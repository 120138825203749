<script setup lang="ts">

import {AlgoliaProduct} from "~/types/AlgoliaResult";
import {TrackingProduct} from "~/types/TrackingProduct";
import {useTracking} from "~/composables/useTracking";

const props = withDefaults(
    defineProps<{
        parent: string;
    }>(),
    {
        parent: 'ProductCard',
    }
);

const product = inject<Ref<AlgoliaProduct>>('product');
const showLowStockMessage = inject<Ref<boolean>>('showLowStockMessage', ref(false));
const qtyActive = ref(false);
const isLoading = ref(false);
const quantity = ref();
const cartItem = ref();

const {trackAddToCart, trackRemoveFromCart} = useTracking();
const {sessionContext} = useSessionContext();
const {refreshCart, cartItems} = useCart();
const {changeItemQuantity, removeItem} = useCartItem(cartItem);

onMounted(() => {
    syncCartItem();
})

watch(cartItems, () => {
    syncCartItem();
});

watch(quantity, () => {
    showLowStockMessage.value = false;
    if (
        product?.value.isCloseout
        && quantity.value > 1
        && quantity.value >= product?.value.availableStock
    ) {
        quantity.value = product.value.availableStock;
        showLowStockMessage.value = true;
    }
    debounceUpdate(quantity.value);
});

const syncCartItem = () => {
    cartItem.value = cartItems.value.find((item) => item.referencedId === product?.value.objectID);
    quantity.value = cartItem.value?.quantity ?? 0;
};

let qtyActiveTimeout = null as NodeJS.Timeout | null;
const qtyKeepAlive = () => {
    if (qtyActiveTimeout) {
        clearTimeout(qtyActiveTimeout);
    }
    hideQty();
};
const hideQty = () => {
    qtyActiveTimeout = setTimeout(() => {
        qtyActive.value = false;
    }, 3500);
};

const updateQuantity = async (qty: number) => {
    if (!product || qty < 1 || (cartItem.value && qty === cartItem.value.quantity)) {
        return;
    }
    isLoading.value = true;


    if (cartItem.value) {
        await changeItemQuantity(qty);
    } else {
        const {addToCart, quantity: quantityToAdd} = useAddToCart(formatProduct(product));
        quantityToAdd.value = qty;
        await addToCart();
    }

    const refreshedCart = await refreshCart();
    isLoading.value = false;

    const trackingProduct = product.value as TrackingProduct;

    refreshedCart.lineItems.forEach((lineItem) => {
        if (lineItem.referencedId === product.value.id) {
            trackingProduct.activePrice = Math.round(((lineItem.price?.unitPrice ?? 0) * 0.8) * 100) / 100;
            trackingProduct.quantity = qty;
        }
    });

    trackAddToCart(
        sessionContext.value,
        [trackingProduct],
        props.parent + ': Add To Cart'
    )

};

let qtyChangeDebouncer = null as NodeJS.Timeout | null;
const debounceUpdate = (qty: number) => {
    if (qtyChangeDebouncer) {
        clearTimeout(qtyChangeDebouncer);
    }

    qtyChangeDebouncer = setTimeout(() => {
        updateQuantity(qty);
    }, 1500);
}

const removeCartItem = async () => {
    if (cartItem.value) {
        isLoading.value = true;
        await removeItem();
        isLoading.value = false;

        trackRemoveFromCart(sessionContext.value, [cartItem.value])
        await refreshCart();
    }
};

const formatProduct = (product: Ref<AlgoliaProduct>) => {
    let formattedProduct = product;
    formattedProduct.value.id = product.value.objectID;
    return formattedProduct;
}

const addToCartProxy = async () => {
    qtyActive.value = true;
    quantity.value = 1;
    hideQty()

    debounceUpdate(1)
};
</script>

<template>
    <div class="product-card-add-to-cart flex flex-col">
        <div v-if="qtyActive || cartItem"
            class="
                quantity-modifier
                flex
                bg-white
                justify-between
                w-[46px]
                h-[46px]
                position-absolute
                absolute-y-center
                right-0
                overflow-hidden
                transition-property-width
                transition-duration-400
                max-w-[215px]
            "
            :class="{ 'w-full qty-active': qtyActive }"
            @click.prevent="qtyActive = true"
            @mouseenter="qtyKeepAlive"
            @mouseleave="hideQty"

        >
            <button
                v-if="quantity === 1"
                type="button"
                :disabled="isLoading"
                class="
                    qty-btn
                    btn-link
                    flex
                    flex-items-center
                    flex-justify-center
                "
                :class="{'disabled': isLoading }"
                @click.prevent="removeCartItem(); hideQty()"
            >
                <NuxtImg
                    src="/trash.svg"
                    height="15"
                    width="15"
                    :alt="$t('cart.remove')"
                />
            </button>
            <button
                v-else
                class="btn-link qty-btn text-black"
                @click.prevent="quantity > 1 ? quantity-- : removeCartItem(); qtyKeepAlive()"
                :class="{ 'disabled': isLoading }"
                :disabled="isLoading"
            >
                <svg width="10" height="5" viewBox="0 0 15 5" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1.39661H10.86" stroke="currentColor" stroke-width="1.5"
                          stroke-linejoin="round"/>
                </svg>
            </button>
            <input
                v-model="quantity"
                type="number"
                :disabled="isLoading"
                :min="cartItem?.quantityInformation?.minPurchase || 1"
                :max="cartItem?.quantityInformation?.maxPurchase || maxQty"
                :step="cartItem?.quantityInformation?.purchaseSteps || 1"
                name="quantity"
                inputmode="numeric"
                class="
                    text-[16px]
                    text-white
                    text-center
                    font-medium
                    line-height-0
                    h-[46px]
                    w-[46px]
                    m-0
                    bg-brand-bottleGreen
                    border-none rounded-full
                    cursor-pointer
                    focus:outline-none focus:ring-none
                "
                :class="{ 'disabled opacity-70': isLoading }"
            />
            <button
                class="btn-link qty-btn"
                @click.prevent="quantity++; qtyKeepAlive()"
                :class="{ 'disabled': isLoading || showLowStockMessage }"
                :disabled="isLoading || showLowStockMessage"
            >
                <svg width="8" height="8" viewBox="0 0 11 11" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.42969 0V10.86" stroke="currentColor" stroke-width="1.5"
                          stroke-linejoin="round"/>
                    <path d="M0 5.39661H10.86" stroke="currentColor" stroke-width="1.5"
                          stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <div v-else
            class="add-to-cart-button"
        >
            <button
                type="button"
                class="
                    pt-[12px]
                    pr-[13px]
                    pb-[8px]
                    pl-[9px]
                    m-0
                    bg-brand-bottleGreen
                    border-none rounded-full
                    cursor-pointer
                    focus:outline-none focus:ring-none
                "
                @click.prevent="addToCartProxy"
            >
                <NuxtImg
                    src="/cart-white.svg"
                    height="23"
                    :alt="$t('listing.buyNow')"
                />
            </button>
        </div>
    </div>
</template>

<style scoped>
.qty-active {
    .qty-btn {
        opacity: 100;
        display: block;
    }
}

.qty-btn {
    transition: width .4s;
    opacity: 0;
    background-color: #f2f2f2;
    color: #000;
    height: 46px;
    width: 46px;
    display: none;
}
</style>
